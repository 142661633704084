<template>
  <div class="sourcery__container">
    <AppSwitcherToListing class="justify-end" />

    <!-- main view -->
    <component
      :is="isListingView ? 'ListingLibraries' : 'LibrariesTable'"
      class="mt-6"
      @click-action-menu="setMenuItem($event)" />

    <!-- menu actions triggered on dots click -->
    <LibrariesActions
      :item.sync="selectedItem"
      :position-x="positionX"
      :position-y="positionY"
      :show-activator="false"
      :value.sync="showActionsCard" />

    <!-- Dialog box to create library -->
    <CollectionsLibrarysRelocate
      @saveNewItem="saveNewLibrary"
      @updateCollectionOrLibrary="updateLibrary" />
  </div>
</template>
<script>
import {
  mapState,
  mapActions,
  mapMutations,
} from 'vuex';

// components
import AppSwitcherToListing from '@/components/App/AppListingElements/AppSwitcherToListing';
import CollectionsLibrarysRelocate from '@/components/CollectionsLibrarysRelocate';
import LibrariesActions from '@/components/Libraries/LibrariesActions';

// mixins
import AppActionDotsMenu from '@/mixins/AppActionDotsMenu';
import ToggleViewCondition from '@/mixins/ToggleViewCondition';

// services
import LibrariesApi from '@/services/graphql/libraries';

export default {
  name: 'Libraries',
  components: {
    AppSwitcherToListing,
    CollectionsLibrarysRelocate,
    LibrariesActions,
    LibrariesTable: () => import('@/components/Libraries/LibrariesTable'),
    ListingLibraries: () => import('@/components/Listing/Libraries'),
  },
  mixins: [ToggleViewCondition, AppActionDotsMenu],
  computed: {
    ...mapState(['updateLibraryOrCollection']),
    ...mapState('Workspace', ['activeWorkspaceId']),
    isListingView() {
      return this.toggleViewCondition('librariesRelatedPages');
    },
  },
  async created() {
    await this.getLibrariesList();
  },
  methods: {
    ...mapActions({
      getLibrariesList: 'Libraries/getLibrariesList',
      setNewLibrary: 'Libraries/setNewLibrary',
      handleError: 'handleError',
      updateLibraryName: 'Libraries/updateLibraryName',
    }),
    ...mapMutations(['spinner']),
    async saveNewLibrary(items) {
      this.spinner(true);
      const { name } = items;
      try {
        const workspaceId = this.activeWorkspaceId;
        const create = 'createV2';
        const { data } = await LibrariesApi[create]({
          name,
          workspaceId,
        });
        this.setNewLibrary(data.response);
      } catch (err) {
        this.handleError(err);
      } finally {
        this.spinner(false);
      }
    },
    async updateLibrary(items) {
      this.spinner(true);
      const { name, description, logo } = items;
      const { id } = this.updateLibraryOrCollection;
      try {
        const workspaceId = this.activeWorkspaceId;
        const data = await LibrariesApi.update({
          libraryId: id,
          name,
          description,
          logo,
          workspaceId,
        });
        this.updateLibraryName(data.data.response);
      } catch (err) {
        this.handleError(err);
      } finally {
        this.spinner(false);
      }
    },
  },
};
</script>
